var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "q-list",
              { staticClass: "rounded-borders", attrs: { bordered: "" } },
              [
                _c(
                  "q-expansion-item",
                  {
                    attrs: {
                      dense: "",
                      dark: "",
                      icon: "place",
                      "header-class": "bg-blue-grey-6 text-white",
                      "expand-icon-class": "text-white",
                      label: "노드 정보",
                    },
                  },
                  [
                    _c("q-separator"),
                    _c("q-card", { staticClass: "q-pa-sm" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-6 col-lg-3" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "노드",
                                value:
                                  _vm.popupParam.node.nodeTitle +
                                  "(" +
                                  _vm.popupParam.node.nodeNo +
                                  ")",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-6 col-lg-3" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "검토기간",
                                value:
                                  _vm.popupParam.node.startStudyDate +
                                  "~" +
                                  _vm.popupParam.node.endStudyDate,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-6" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                row: 2,
                                title: "노드설명",
                                value: _vm.popupParam.node.nodeDesc,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-6" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                row: 2,
                                title: "설계조건",
                                value: _vm.popupParam.node.designConditions,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-6" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                row: 2,
                                title: "작동조건",
                                value: _vm.popupParam.node.operatingConditions,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-6" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                row: 2,
                                title: "설계의도",
                                value: _vm.popupParam.node.designIntent,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "c-table",
        {
          ref: "table",
          staticClass: "q-mt-sm",
          attrs: {
            title: "구간별 가이드워드",
            tableId: "guideword01",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            gridHeight: _vm.grid.height,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            hideBottom: true,
            selection: "multiple",
            rowKey: "ramVariableId",
            contentsField: _vm.contentsField,
          },
          on: { changeSelection: _vm.changeSelection },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBLSELECT", icon: "check" },
                        on: { btnClicked: _vm.select },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }